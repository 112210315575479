@import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css';
main{min-height:80vh;}
h3{padding:1rem 0;}
.rating span{margin:0.1rem;}
.reglink{text-decoration:none;}
.reglink:hover{text-decoration:underline;}
.btn{text-transform:none !important;letter-spacing:0.03em;}
/* carousel */
.carousel-item-next, .carousel-item-prev, .carousel-item.active {display: flex;}
.carousel-caption {position: absolute;top: 0;}
.carousel-caption h4 {color: #fff;}
.carousel img {display:block;height: 300px;padding: 30px;border-radius: 50%;
    margin:40px auto;
}
.carousel a {margin: 0 auto;}
@media (max-width: 900px) {
  .carousel-caption h2 {
    font-size: 2.5vw;
  }
}
